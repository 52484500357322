.AppHeader {
  background-image: url("../assets/appheader-background.jpg");
  background-position: left left;
  background-repeat: no-repeat;
  background-size: cover;
}

.AppHeaderFullLogo {
  flex-direction: column;
  margin-top: -4vh;
  position: fixed;
}

.AppHeaderWhiteLogo {
  height: 8vh;
  width: 11vh;
  background-image: url("../assets/logo-white.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.AppHeaderTagline {
  height: 2vh;
  width: 11vh;
  margin-top: 10px;
  background-image: url("../assets/tagline.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.NavigationButtons {
  position: absolute;
  text-align: right;
  right: 0px;
  width: 60%;
}

.NavigationButton {
  margin: 2vw;
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}

.NavigationButton:hover {
  color: rgba(255, 255, 255, 0.5);
}