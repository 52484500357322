.App {
  text-align: center;
}

.TextWrapper {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 5px;
}

.TextBundler {
  display: block;
  align-items: center;
  justify-content: center;
}

.Paddinated {
  padding: 5px;
}

.Footer {
  height: 20vh;
  padding-top: 5vh;
  background-color: rgba(230, 195, 156, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RouterButton {
  text-decoration: none;
}

.EmjeLogo {
  height: 100%;
  width: 10vw;
  background-image: url("./assets/emje-logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1vw;
  margin-right: 1vw;
}

.FlandriaRentLogo {
  height: 100%;
  width: 7vw;
  background-image: url("./assets/flandria-rent-logo.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1vw;
  margin-right: 1vw;
}

.GroupDaedalusLogo {
  height: 100%;
  width: 10vw;
  background-image: url("./assets/group-daedalus-logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1vw;
  margin-right: 1vw;
}

.ManeblusserLogo {
  height: 100%;
  width: 7vw;
  background-image: url("./assets/maneblusser-logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1vw;
  margin-right: 1vw;
}

.WimVerhuurLogo {
  height: 100%;
  width: 7vw;
  background-image: url("./assets/wim-verhuur-logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1vw;
  margin-right: 1vw;
}

/*Very Smoll devices (portrait phones, 576px and below)*/
@media (max-width: 576px) {
  .EmjeLogo {
    width: 20vw;
  }

  .FlandriaRentLogo {
    width: 15vw;
  }

  .GroupDaedalusLogo {
    width: 20vw;
  }

  .ManeblusserLogo {
    width: 15vw;
  }

  .WimVerhuurLogo {
    width: 15vw;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .EmjeLogo {
    width: 10vw;
  }

  .FlandriaRentLogo {
    width: 7vw;
  }

  .GroupDaedalusLogo {
    width: 10vw;
  }

  .ManeblusserLogo {
    width: 7vw;
  }

  .WimVerhuurLogo {
    width: 7vw;
  }
}