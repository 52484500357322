.Lineup {
  background-color: rgba(230, 195, 156, 255);
  padding-top: 12vh;
}

.Lineup::after {
  content: "";
  display: table;
  clear: both;
}

.LineupDay {
  float: left;
  width: 50%;
}

.LineupBands {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.RouterButton {
  text-decoration: none;
}