.Home {
  padding-top: 10vh;
  min-height: 70vh;
  background-image: url("../assets/home-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.WhiteLogo {
  background-image: url("../assets/logo-white.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Tagline {
  margin-bottom: 5vh;
  background-image: url("../assets/tagline.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.BottomBar {
  padding: 10px;
  min-height: 10vh;
  background-color: rgba(230, 195, 156, 255);
}

.HomeRouterButton {
  margin: 10px !important;
  text-decoration: none;
}

/*Very Smoll devices (portrait phones, 576px and below)*/
@media (max-width: 576px) {
  .WhiteLogo {
    min-height: 25vh;
  }

  .Tagline {
    min-height: 3vh;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .WhiteLogo {
    min-height: 29vh;
  }

  .Tagline {
    min-height: 3.5vh;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .WhiteLogo {
    min-height: 33vh;
  }

  .Tagline {
    min-height: 4vh;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .WhiteLogo {
    min-height: 37vh;
  }

  .Tagline {
    min-height: 4.5vh;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .WhiteLogo {
    min-height: 40vh;
  }

  .Tagline {
    min-height: 5vh;
  }
}
